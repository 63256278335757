<template>
  <div class="crypto-info">
    <h2>主要加密货币的</h2>
    <h3>Global Market Cap: ${{ formatVolume(globalMarketCap) }}</h3>

    <div class="content">
      <table class="crypto-table">
        <thead>
          <tr>
            <th @click="sortTable('symbol')">
              Symbol
              <span v-if="sortKey === 'symbol'">{{
                sortOrder === "asc" ? "↑" : "↓"
              }}</span>
            </th>
            <th @click="sortTable('price')">
              Price (USD)
              <span v-if="sortKey === 'price'">{{
                sortOrder === "asc" ? "↑" : "↓"
              }}</span>
            </th>
            <th @click="sortTable('market_cap')">
              Market Cap (USD)
              <span v-if="sortKey === 'market_cap'">{{
                sortOrder === "asc" ? "↑" : "↓"
              }}</span>
            </th>
            <th @click="sortTable('volume')">
              24h Volume (USD)
              <span v-if="sortKey === 'volume'">{{
                sortOrder === "asc" ? "↑" : "↓"
              }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="coin in sortedCoins"
            :key="coin.symbol"
            :class="getRowClass(coin.price_change_percentage_24h)"
          >
            <td>{{ coin.symbol }}</td>
            <td :style="getPriceStyle(coin.price_change_percentage_24h)">
              {{
                formatPriceWithChange(
                  coin.price,
                  coin.price_change_percentage_24h
                )
              }}
            </td>
            <td>${{ formatVolume(coin.market_cap) }}</td>
            <td>${{ formatVolume(coin.volume) }}</td>
          </tr>
        </tbody>
      </table>

      <div class="chart-container">
        <PieChart v-if="chartData.labels.length > 0" :chartData="chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import PieChart from "./PieChart.vue"; // Ensure this import is correct

export default {
  name: "CryptoInfo",
  components: {
    PieChart,
  },
  setup() {
    const coins = ref([]);
    const globalMarketCap = ref(0);
    const chartData = ref({
      labels: [],
      datasets: [
        {
          label: "Market Cap Distribution",
          data: [],
          backgroundColor: [],
        },
      ],
    });
    let intervalId = null;
    const sortKey = ref("market_cap"); // 默认按照市值排序
    const sortOrder = ref("desc"); // 默认降序

    const fetchCryptoData = async () => {
      try {
        const response = await fetch("https://api.coingecko.com/api/v3/global");
        const globalData = await response.json();
        globalMarketCap.value = globalData.data.total_market_cap.usd;

        const coinsResponse = await fetch(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,ethereum,binancecoin,okb,solana,tether,usd-coin&order=market_cap_desc&per_page=100&page=1&sparkline=false"
        );
        const data = await coinsResponse.json();
        coins.value = data.map((coin) => ({
          symbol: coin.symbol.toUpperCase(),
          price: coin.current_price,
          market_cap: coin.market_cap,
          volume: coin.total_volume,
          price_change_percentage_24h: coin.price_change_percentage_24h || 0, // Fallback to 0 if undefined
        }));

        updateChartData();
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    const updateChartData = () => {
      const btc = coins.value.find((coin) => coin.symbol === "BTC");
      const eth = coins.value.find((coin) => coin.symbol === "ETH");
      const usdt = coins.value.find((coin) => coin.symbol === "USDT");
      const usdc = coins.value.find((coin) => coin.symbol === "USDC");
      const othersMarketCap =
        globalMarketCap.value -
        (btc ? btc.market_cap : 0) -
        (eth ? eth.market_cap : 0) -
        (usdt ? usdt.market_cap : 0) -
        (usdc ? usdc.market_cap : 0);

      chartData.value.labels = [];
      chartData.value.datasets[0].data = [];

      if (btc) {
        chartData.value.labels.push(btc.symbol); // Use symbol instead of name
        chartData.value.datasets[0].data.push(
          (btc.market_cap / globalMarketCap.value) * 100
        );
      }

      if (eth) {
        chartData.value.labels.push(eth.symbol); // Use symbol instead of name
        chartData.value.datasets[0].data.push(
          (eth.market_cap / globalMarketCap.value) * 100
        );
      }

      if (usdt) {
        chartData.value.labels.push(usdt.symbol);
        chartData.value.datasets[0].data.push(
          (usdt.market_cap / globalMarketCap.value) * 100
        );
      }

      if (usdc) {
        chartData.value.labels.push(usdc.symbol);
        chartData.value.datasets[0].data.push(
          (usdc.market_cap / globalMarketCap.value) * 100
        );
      }

      if (othersMarketCap > 0) {
        chartData.value.labels.push("Others");
        chartData.value.datasets[0].data.push(
          (othersMarketCap / globalMarketCap.value) * 100
        );
      }

      chartData.value.datasets[0].backgroundColor = chartData.value.labels.map(
        () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
      );
    };

    const formatVolume = (volume) => {
      return volume.toLocaleString("en-US", { maximumFractionDigits: 0 });
    };

    // Filtered coins to show only BTC, ETH, BNB, OKB, and SOL
    const filteredCoins = computed(() => {
      return coins.value.filter((coin) =>
        ["BTC", "ETH", "BNB", "OKB", "SOL", "USDT", "USDC"].includes(
          coin.symbol
        )
      );
    });

    // Function to format price with change percentage
    const formatPriceWithChange = (price, change) => {
      const arrow = change > 0 ? "↑" : change < 0 ? "↓" : "";
      return `$${price.toFixed(2)} (${change.toFixed(2)}%) ${arrow}`;
    };

    // Function to determine price style based on price change percentage
    const getPriceStyle = (priceChange) => {
      return priceChange > 0
        ? { color: "#006400" }
        : priceChange < 0
        ? { color: "red" }
        : { color: "black" };
    };

    // Function to determine row class based on price change
    const getRowClass = (priceChange) => {
      return priceChange > 0 ? "row-up" : priceChange < 0 ? "row-down" : "";
    };

    // Function to sort the table
    const sortTable = (key) => {
      sortOrder.value =
        sortKey.value === key && sortOrder.value === "asc" ? "desc" : "asc";
      sortKey.value = key;
    };

    // Computed property for sorted coins
    const sortedCoins = computed(() => {
      return [...filteredCoins.value].sort((a, b) => {
        const modifier = sortOrder.value === "asc" ? 1 : -1;
        if (sortKey.value === "market_cap") {
          return (a.market_cap - b.market_cap) * modifier;
        } else if (sortKey.value === "price") {
          return (a.price - b.price) * modifier;
        } else if (sortKey.value === "volume") {
          return (a.volume - b.volume) * modifier;
        } else {
          return (a.symbol > b.symbol ? 1 : -1) * modifier;
        }
      });
    });

    onMounted(() => {
      fetchCryptoData();
      intervalId = setInterval(fetchCryptoData, 60000);
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    return {
      coins,
      globalMarketCap,
      chartData,
      formatVolume,
      filteredCoins,
      formatPriceWithChange,
      getPriceStyle,
      getRowClass,
      sortTable,
      sortedCoins,
      sortKey,
      sortOrder,
    };
  },
};
</script>

<style scoped>
.crypto-info {
  width: 100%; /* 设置宽度为100% */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  align-items: stretch; /* Ensures both elements stretch to the same height */
}

.crypto-table {
  width: 70%; /* Adjust width as needed */
  border-collapse: collapse;
  margin-right: 20px; /* Space between table and chart */
}

.crypto-table th,
.crypto-table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better spacing */
  text-align: left;
}

.crypto-table th {
  background-color: #4caf50; /* Darker green for header */
  color: white; /* White text for header */
  cursor: pointer; /* Pointer cursor for sortable columns */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.crypto-table th:hover {
  background-color: #45a049; /* Slightly lighter green on hover */
}

.row-up {
  background-color: rgba(144, 238, 144, 0.5); /* Light green for price up */
}

.row-down {
  background-color: rgba(255, 99, 71, 0.5); /* Light red for price down */
}

.chart-container {
  width: 30%; /* Adjust width as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

h3 {
  text-align: center;
}
</style>