<template>
  <div class="etf-flow-monitor">
    <h2>BTC 和 ETH ETF 流入和流出监控</h2>
    <div class="iframe-container">
      <iframe
        :src="bitcoinSrc"
        width="100%"
        height="420"
        frameborder="0"
        title="Spot Bitcoin ETF Total Net Flows"
      ></iframe>
      <iframe
        :src="ethereumSrc"
        width="100%"
        height="420"
        frameborder="0"
        title="Spot Ethereum ETF Total Net Flows"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "EtfFlowMonitor",
  setup() {
    const intervalId = ref(null);
    const bitcoinSrc = ref("");
    const ethereumSrc = ref("");

    const fetchData = () => {
      bitcoinSrc.value = `https://www.theblock.co/data/crypto-markets/bitcoin-etf/spot-bitcoin-etf-total-net-flow/embed`;
      ethereumSrc.value = `https://www.theblock.co/data/crypto-markets/ethereum-etf/spot-ethereum-etf-total-net-flows/embed`;
    };

    onMounted(() => {
      fetchData(); // 初始加载数据
      intervalId.value = setInterval(fetchData, 300000); // 每5分钟更新一次
    });

    onUnmounted(() => {
      clearInterval(intervalId.value); // 清除定时器
    });

    return {
      bitcoinSrc,
      ethereumSrc,
    };
  },
};
</script>

<style scoped>
.etf-flow-monitor {
  width: 100%; /* 设置宽度为100% */
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.etf-flow-monitor h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 15px;
}

.iframe-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* 添加间距 */
}
</style>
