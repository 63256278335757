<template>
  <div>
    <canvas ref="canvas" width="400" height="400"></canvas>
  </div>
</template>

<script>
import { defineComponent, ref, watch, nextTick } from 'vue';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

export default defineComponent({
  name: 'PieChart',
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const canvas = ref(null);
    let chartInstance = null;

    const renderChart = async () => {
      await nextTick(); // Ensure the DOM is updated before rendering the chart
      setTimeout(() => {
        if (canvas.value) {
          if (chartInstance) {
            chartInstance.destroy();
          }
          chartInstance = new Chart(canvas.value, {
            type: 'pie',
            data: props.chartData,
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Market Cap Distribution'
                },
                datalabels: {
                  formatter: (value, context) => {
                    return context.chart.data.labels[context.dataIndex] + ': ' + value.toFixed(2) + '%';
                  },
                  color: '#fff',
                }
              }
            }
          });
        } else {
          console.error('Canvas reference is not valid:', canvas.value);
        }
      }, 100); // Delay for 100ms
    };

    watch(() => props.chartData, renderChart, { immediate: true });

    return {
      canvas
    };
  }
});
</script>

<style scoped>
/* Add any styles you need for the PieChart */
</style>